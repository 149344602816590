<template>
  <div class="bottomBox">
    <ul>
      <template v-for="(item, index) in cgList">
        <li v-if="activityInfoData.join_TeachingStudio || index == 0" :key="index" class=""
          @click="onSearchCg(item.id)">
          <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </template>
    </ul>
    <div class="Art_right">
      <div class="demoBox">
        <!-- //循环 -->
        <!-- @click="onGoDetail(item.id, item.resource_id)" -->
        <div v-for="(item, index) in ResData" :key="index" class="forBox cursor">
          <div class="oneBox">
            <div class="topImg">
              <img :src="onShowImages(item.mime)" alt="" />
            </div>
            <div class="bottomTxt">{{ item.sources_name }}</div>
          </div>
          <div class="twoBox">
            <new-viewonline :viewSrc="item.view_url"
              :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
              :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
              :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time" fileNav="-">
              <el-link targer="_blank" :underline="false">
                <p>{{ item.title }}</p>
              </el-link>
            </new-viewonline>
            <p>
              {{ item.pharse_name || "-" }}
              {{ item.subject_name || "-" }} /
              {{ item.grade_name || "-" }}
            </p>
            <p>
              <span>上传时间：{{ format(item.create_time) }}</span>
              <span>收藏次数：{{ item.is_collect }}</span>
              <span> 文件大小：{{ item.size | fileSizeFilter }}</span>
            </p>
          </div>
        </div>
        <CommonEmpty v-if="ResData.length == 0" hVal="200px"></CommonEmpty>
      </div>
      <!-- 分页 -->
      <div v-if="total" class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
          :page-sizes="[5, 10, 20, 30]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onShowImages } from "@/utils/utils";
export default {
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      cgList: [
        {
          name: "全部成果",
          id: 0,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: "我的成果",
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
      active: 0,
      form: {
        page: 1,
        limit: 5,
        type: 2, //来源：0：全部 1：教研直播 2：教研活动 3：研修视频
        all_my: 0, //0 全部成果 1 我的成果
        id: "",
        source_id: 0, //	资源类型id
      },
      ResData: [],
      total: 0,
    };
  },
  created() {
    let query = this.$route.query;
    this.is_from = query.is_from || 1;
    this.form.id = query.activityId;
  },
  mounted() {
    if (Object.keys(this.activityInfoData).length > 0) {
      this.onGetAchievement();
    }
  },
  methods: {
    onShowImages,
    //时间戳转化
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    // 全部成果/活动成果
    onSearchCg(id) {
      this.active = id;
      this.form.page = 1;
      this.form.all_my = id;
      this.onGetAchievement();
    },
    // 活动成果
    onGetAchievement() {
      let p = null;
      if (this.is_from == 1) {
        this.form.group_id = this.activityInfoData.group_id;
        p = this.$axios.get("TeacherGroup/get_resource_list", {
          params: this.form,
        });
      } else {
        this.form.teaching_studio_id = this.activityInfoData.group_id;
        p = this.$axios.get(`TeachingStudio/get_resource_list`, {
          params: this.form,
        });
      }
      p.then((res) => {
        this.ResData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    //分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.onGetAchievement();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.onGetAchievement();
    },
  },
  watch: {
    activityInfoData(e) {
      this.onGetAchievement();
    },
  },
};
</script>

<style lang="less" scoped>
.bottomBox {
  padding: 30px;

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    li {
      margin-right: 10%;

      >img {
        vertical-align: text-bottom;
        margin-right: 16px;
      }
    }
  }

  .Art_right {

    // width: 1106px;
    // height: 999px;
    // padding: 0;
    // margin: 0;
    // box-sizing: border-box;
    // background: #ffffff;
    // border-radius: 4px;
    // margin-bottom: 60px;
    // padding: 32px 26px;
    .demoBox {
      .forBox {
        display: flex;
        align-items: center;
        border: 1px solid #ebebeb;
        margin-bottom: 20px;
        padding: 21px 21px;
        position: relative;
        border-radius: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        .oneBox {
          cursor: pointer;
          width: 120px;
          height: 120px;
          background: #ffffff;
          border: 1px solid #ececec;
          border-radius: 7px;

          .topImg {
            height: 82px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 68px;
              height: 48px;
            }
          }

          .bottomTxt {
            width: 100%;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ececec;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        .twoBox {
          //第一行标题
          margin-left: 20px;

          p:nth-of-type(1) {
            font-size: 16px;
            text-align: left;
            color: #333333;
            margin-bottom: 14px;
          }

          //第二行
          p:nth-of-type(2) {
            // width: 235px;
            // height: 16px;
            font-size: 16px;
            text-align: left;
            color: #999999;
            margin-bottom: 16px;
          }

          p:nth-of-type(3) {
            // width: 423px;
            height: 12px;
            font-size: 14px;
            color: #999999;
            margin-top: 12px;
          }

          //标签
          .twolist {
            display: flex;

            div {
              width: 69px;
              height: 24px;
              opacity: 1;
              background: #ecf5ff;
              border: 1px solid #d9ecff;
              border-radius: 3px;
              text-align: center;
              color: #409eff;
            }

            div:nth-of-type(1) {
              margin-right: 8px;
            }
          }

          span {
            margin-right: 10px;
          }
        }
      }
    }

    .block {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
</style>
